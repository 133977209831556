/* FONTS */
@import "https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i";
body {
  font-family: 'Montserrat', sans-serif;
}

#left {
  position: fixed;
  height: 100%;
  width: 20%;
  z-index: 100;
  background-color: #CCC;
}

#main {
  margin-left: 20%;
}

.sidebar {
  height: 100%;
}

.brand h1 {
  margin: 0;
  padding-top: 25px;
  font-size: 1.4vw;
  letter-spacing: 2px;
  padding-left: 15px;
}

@media screen and (max-width: 599px) {
  .brand h1 {
    font-size: 23px;
  }
}

.brand h1 a {
  text-decoration: none;
  text-transform: uppercase;
  color: #FEFFFD;
  outline: none;
}

.brand h2 {
  color: #FEFFFD;
  font-size: 1.45vw;
  letter-spacing: 2px;
  padding-left: 15px;
}

@media screen and (max-width: 599px) {
  .brand h2 {
    font-size: 25px;
  }
}

.brand h3 {
  color: #FEFFFD;
  text-transform: uppercase;
  font-size: 0.9vw;
  letter-spacing: 1.8px;
  font-weight: 700;
  padding-left: 15px;
}

@media screen and (max-width: 599px) {
  .brand h3 {
    font-size: 15px !important;
  }
}

.brand .social-block {
  width: 100%;
  margin-top: 30px;
}

.brand .social-icon {
  font-size: 2vw !important;
  width: auto;
  height: auto;
  max-width: none !important;
  max-height: none !important;
  color: white;
  opacity: 0.9;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 599px) {
  .brand .social-icon {
    font-size: 35px !important;
  }
}

.brand .social-icon:hover {
  opacity: 1;
}

feed p {
  text-transform: none;
}

.upper {
  text-transform: uppercase;
}

.feed-wrapper {
  overflow-y: hidden;
  height: 100%;
}

.feed-wrapper .feed-header h1 {
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 0.9vw;
  letter-spacing: 1px;
  margin: 0;
  padding-left: 15px;
  padding-bottom: 10px;
}

@media screen and (max-width: 599px) {
  .feed-wrapper .feed-header h1 {
    font-size: 15px !important;
  }
}

.feed-wrapper .feed-container {
  height: 100%;
}

.feed-wrapper .feed-item {
  padding-left: 15px;
  padding-right: 15px;
  color: #555555;
  font-size: 13px;
  line-height: 1.2em;
  margin-bottom: 0;
  border-bottom: 1px solid;
}

.feed-wrapper .feed-item .feed-item-text {
  margin: 0;
  padding-top: 10px;
}

.feed-wrapper .feed-item .feed-item-text a {
  text-decoration: none;
  color: inherit;
}

.feed-wrapper .feed-item .feed-item-text a:hover {
  text-decoration: underline;
}

.feed-wrapper .feed-item .feed-item-datetime {
  font-size: 11px;
}

.content {
  background-size: cover;
  z-index: 0;
  height: 100%;
  transition: opacity 300ms ease !important;
}

.topmenu {
  position: fixed;
  width: 80%;
  z-index: 100;
  background-color: white;
}

menu {
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  z-index: 1000;
}

.home-background {
  background-size: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 599px) {
  .home-background {
    height: 300px;
  }
}

.clip-wrapper {
  padding-right: 20px;
}

@media screen and (min-width: 960px) {
  .clip-wrapper:nth-child(3n) {
    padding-right: 0;
  }
}

@media screen and (max-width: 959px) {
  .clip-wrapper:nth-child(2n) {
    padding-right: 0;
  }
}

@media screen and (max-width: 600px) {
  .clip-wrapper {
    padding-right: 0;
  }
}

.press-clipping {
  max-width: 100%;
  border: 2px solid #dddddd;
  opacity: 0.8;
}

.press-clipping:hover {
  opacity: 1;
}

.publication-title {
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 14px;
}

.publication-date {
  margin-top: 0;
  margin-bottom: 40px;
  color: #bbbbbb;
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 600;
}

.secondary-nav-item {
  text-decoration: none;
  color: #dddddd;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
}

.active {
  color: #000000;
}

.menu-item {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 14px;
}

.menu-item a {
  text-decoration: none;
}

.menu-text-shadow {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  color: #FFF;
}

.menu-no-text-shadow {
  color: #000;
}

.current {
  text-decoration: underline !important;
}

.mobile-menu {
  position: fixed;
  width: 80%;
  right: 0;
  height: 100%;
  background-color: #767676;
  z-index: 100000;
}

.mobile-menu-overlay {
  position: absolute;
  width: 100%;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 90;
}

.mobile-menu-item {
  color: white;
  text-transform: uppercase;
  padding-left: 30px;
  border-top: 1px solid #AAAAAA;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 15px;
  cursor: pointer;
}

.mobile-menu-item a, .mobile-menu-item a:active, .mobile-menu-item a:link {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
}

.mobile-section-header {
  background-color: white;
  box-shadow: 1px 2px 2px #ccc;
}

.mobile-section-header h1 {
  text-transform: uppercase;
  color: #E1E1E1;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-top: 0;
  padding-left: 15px;
}

.mobile-menu-menu {
  color: #FFFFFF;
  padding-top: 25px;
  padding-right: 15px;
  cursor: pointer;
  outline: none;
}

.mobile-menu-close {
  color: #FFFFFF;
  padding-bottom: 50px;
  font-weight: bold;
  position: relative;
  top: 10px;
  left: 85%;
  outline: none;
}

.hide-scroll {
  overflow: hidden;
}

.event-block {
  border-bottom: 2px solid;
  font-weight: 600;
}

@media screen and (max-width: 599px) {
  .event-block {
    color: #fff;
    text-transform: uppercase;
    padding-left: 15px;
  }
}

@media screen and (max-width: 599px) {
  .event-time-place {
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 600;
  }
}

@media screen and (min-width: 600px) {
  .event-time-place {
    padding-top: 20px;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 599px) {
  .event-name {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 3px;
    margin-top: 5px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 600px) {
  .event-name {
    font-size: 36px;
    letter-spacing: 4px;
  }
}

@media screen and (max-width: 599px) {
  .event-company a {
    font-size: 15px;
    text-shadow: 0px 1px #ddd;
    font-weight: bold;
    display: block;
    text-decoration: none;
    color: #FFF;
  }
  .event-company:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: 600px) {
  .event-company a {
    padding-top: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: -30px;
    display: block;
    text-decoration: none;
  }
  .event-company a:hover {
    text-decoration: underline;
  }
}

.event-description {
  padding-bottom: 40px;
  font-weight: 400;
}

.event-image {
  background-position: center center;
  background-size: cover;
  border-bottom: 2px solid;
}

@media screen and (max-width: 599px) {
  .event-image {
    height: 300px;
  }
}

.companies-company-block {
  border-bottom: 2px solid;
  text-transform: uppercase;
}

@media screen and (max-width: 599px) {
  .companies-company-block {
    padding-left: 15px;
  }
  .companies-company-block a {
    color: #fff;
  }
}

@media screen and (max-width: 599px) {
  .company-country {
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 600;
  }
}

@media screen and (min-width: 600px) {
  .company-country {
    padding-top: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 15px;
  }
}

@media screen and (max-width: 599px) {
  .company-name {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 3px;
    margin-top: 5px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 600px) {
  .company-name {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 4px;
    text-decoration: none;
    color: #303030;
  }
  .company-name a {
    text-decoration: inherit;
    opacity: 0.8;
    color: inherit;
  }
  .company-name a:hover {
    text-decoration: underline;
    opacity: 1;
  }
}

.company-presentation {
  padding-bottom: 40px;
  padding-right: 20px;
  text-transform: none;
}

.company-image {
  background-position: center center;
  background-size: cover;
  border-bottom: 2px solid;
}

@media screen and (max-width: 599px) {
  .company-image {
    height: 300px;
  }
}

.company-show {
  font-size: 15px;
  text-shadow: 0px 1px #ddd;
  font-weight: bold;
}

.company-company-block {
  text-transform: uppercase;
}

.company-section-title {
  font-weight: 600;
  margin-top: 40px;
  text-transform: none;
}

.sec-nav-block {
  background-color: #fff;
  outline: none;
}

.sec-nav-block p {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  text-align: center;
}

.filter-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-wrapper .filter-block {
  text-align: center;
  line-height: 12px;
}

.filter-wrapper .filter-link {
  text-decoration: none;
  color: #cacaca;
  outline: none;
  font-weight: bold;
}

.filter-wrapper .venue {
  font-size: 10px;
}

.filter-wrapper .date {
  font-size: 18px;
}

.filter-wrapper .active {
  text-decoration: underline;
  color: #000;
}

.footer {
  padding-top: 20px;
}

@media screen and (max-width: 599px) {
  .footer img {
    margin-left: 5%;
    width: 90%;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 600px) {
  .footer {
    padding-bottom: 20px;
  }
}

.disclaimer {
  font-size: 16px;
  font-weight: 700;
  color: #999;
}

.contacts a {
  text-decoration: none;
  color: #333;
  opacity: 0.8;
}

.contacts a:hover {
  text-decoration: underline;
  opacity: 1;
}

.soon {
  color: red;
  position: absolute;
  top: -5px;
  right: -40px;
  z-index: 9000;
  font-size: 9px;
}

